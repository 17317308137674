declare global {
  interface Window {
    _paq: unknown[];
  }
}

export const launchTrack = (id: number) => {
  const _paq = (window._paq = window._paq || []);
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);
  (function () {
    const url = "//stat.radiowebapp.com/";
    _paq.push(["setTrackerUrl", url + "matomo.php"]);
    _paq.push(["setSiteId", `${id}`]);
    const scriptToAdd = document.createElement("script"),
      getFirstScript = document.getElementsByTagName("script")[0];
    scriptToAdd.async = true;
    scriptToAdd.src = url + "matomo.js";
    getFirstScript.parentNode?.insertBefore(scriptToAdd, getFirstScript);
  })();
};
