import { RadioMetaData } from "@/components/player/type";
import { BASE_API_URL } from "@/utils/env";
import axios, { AxiosError } from "axios";

export const fetchRadioMetaData = async (
  radioName: string
): Promise<RadioMetaData | string> => {
  let radioMetaDataOrError: RadioMetaData | string = "";
  try {
    const response = await axios.get(`${BASE_API_URL}metadata/${radioName}/`);

    if ([200, 201].includes(response.status)) {
      radioMetaDataOrError = response.data;
    } else {
      radioMetaDataOrError =
        "Erreur inattendu: S'il vous plait veuillez raffraichir la page";
    }
  } catch (error: unknown) {
    const apiError = error as AxiosError<{ detail: string }>;
    if (apiError.response?.status === 404) {
      radioMetaDataOrError = `La radio ‹‹${radioName}›› est désactivé, veuillez contacter l'administrateur`;
    } else {
      console.error(error);
    }
  }
  return radioMetaDataOrError;
};
