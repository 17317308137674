<template>
  <div class="py-4 px-10 text-center space-y-2">
    <div class="space-y-2">
      <h2 class="font-bold">1. Partager par QR CODE la RadioWebApp</h2>
      <QrcodeVue class="mx-auto md:hidden" :value="url" :size="200" />
      <QrcodeVue
        class="mx-auto hidden md:block lg:hidden"
        :value="url"
        :size="300"
      />
      <QrcodeVue class="mx-auto hidden lg:block" :value="url" :size="200" />
    </div>
    <div class="space-y-2">
      <h2 class="font-bold">2. Partager autrement en utilisant ce bouton:</h2>
      <button
        class="px-4 py-3 bg-black w-fit mx-auto rounded-lg text-[11px] sm:text-base text-white uppercase flex gap-x-1 sm:gap-x-2 items-center justify-center"
        @click="shareElseWhere"
      >
        <ShareIcon class="h-3 w-3 sm:h-5 sm:w-5 stroke-white" />
        <span>partager autrement</span>
      </button>
    </div>
    <div class="space-y-3">
      <h2 class="font-bold">3. Installer la RadioWebApp</h2>
      <div class="flex flex-col items-center">
        <button
          v-if="isInstallationSupported"
          class="bg-black duration-150 ease-in text-[11px] sm:text-base w-fit hover:bg-black/85 uppercase text-white px-4 py-3 rounded-lg flex gap-x-1 sm:gap-x-2 items-center justify-center"
          @click.prevent="installPWA"
        >
          <InstallIcon class="h-3 w-3 sm:h-5 sm:w-5 fill-white" />
          <span>Installer l'application</span>
        </button>
        <div
          v-if="!isInstallationSupported"
          class="text-sm md:text-base text-center"
        >
          L'installation n'est pas encore supporté par ce navigateur ou cette
          version. Veuillez utiliser à la place soit
          <span class="font-semibold italic">Google chrome</span>, soit
          <span class="font-semibold italic">Brave</span>, soit
          <span class="font-semibold italic">Opera</span>, soit
          <span class="font-semibold italic">Microsoft Edge</span>.
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import QrcodeVue from "qrcode.vue";
import ShareIcon from "@/assets/ShareIcon.vue";
import InstallIcon from "@/assets/InstallIcon.vue";
import shareRadio from "@/utils/share";
import { ref, defineProps } from "vue";
import { deferredPrompt, isInstallationSupported } from "@/utils/installation";

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const shareElseWhere = async (): Promise<void> => {
  await shareRadio(props.url);
};

const shouldHideModal = ref(false);
const installPWA = async () => {
  if (!deferredPrompt.value) return;

  await deferredPrompt.value?.prompt();
  const { outcome } = await deferredPrompt.value?.userChoice;
  if (outcome === "dismissed") {
    deferredPrompt.value = null;
    isInstallationSupported.value = false;
  } else {
    shouldHideModal.value = true;
  }
};
</script>
