<template>
  <a
    :href="currentPublicity?.publicity_link"
    target="_blank"
    class="block relative"
    ref="pub"
  >
    <div
      v-if="isImageLoading"
      class="w-full h-full rounded-[4px] skeleton"
    ></div>
    <template v-else>
      <img
        :src="currentPublicity?.pub_image"
        class="mx-auto rounded xl:h-[500px]"
      />
      <div
        v-if="
          currentPublicity?.description && currentPublicity.description !== '-'
        "
        class="absolute lg:text-lg z-10 bottom-0 left-0 p-3 bg-[rgba(0,0,0,0.7)] w-full rounded-b"
      >
        {{
          currentPublicity.description.length > 101
            ? `${currentPublicity.description.slice(0, 101)}...`
            : currentPublicity.description
        }}
      </div>
    </template>
  </a>
</template>
<script setup lang="ts">
import { PropType, ref, watch } from "vue";
import { Publicity } from "./type";

const props = defineProps({
  publicity: {
    type: Array as PropType<Publicity[]>,
    required: true,
  },
});

const currentPublicityIndex = ref(0);
const currentPublicity = ref<Publicity>();
const pubIndexIncrement = (): void => {
  if (props.publicity.length === currentPublicityIndex.value + 1) {
    currentPublicityIndex.value = 0;
  } else {
    currentPublicityIndex.value++;
  }
};

let timeOut: ReturnType<typeof setTimeout>;
const timeOutCallback = (): void => {
  currentPublicity.value = props.publicity[currentPublicityIndex.value];
  timeOut = setTimeout(() => {
    pubIndexIncrement();
    initPubChange();
  }, 15_000);
};

const pub = ref<HTMLLinkElement>();
const isImageLoading = ref(false);
const imageWithVisibleSkeletonLoading = (url: string) => {
  if (pub.value) pub.value.style.minHeight = "400px";
  isImageLoading.value = true;
  const img = new Image();
  img.src = url;

  if (img.complete) {
    timeOutCallback();
    if (pub.value) {
      pub.value.style.minHeight = "auto";
    }
    isImageLoading.value = false;
  } else {
    img.onload = () => {
      isImageLoading.value = true;
      if (img.complete) {
        if (pub.value) {
          pub.value.style.minHeight = "auto";
        }
        timeOutCallback();
        isImageLoading.value = false;
      }
    };
  }
};

const imageWithInvisibleSkeletonLoading = (url: string) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    timeOutCallback();
  } else {
    img.onload = () => {
      if (img.complete) {
        timeOutCallback();
      }
    };
  }
};

const initPubChange = (): void => {
  if (currentPublicityIndex.value) {
    imageWithInvisibleSkeletonLoading(
      props.publicity[currentPublicityIndex.value].pub_image
    );
  } else {
    imageWithVisibleSkeletonLoading(
      props.publicity[currentPublicityIndex.value].pub_image
    );
  }
};

initPubChange();

const areTheSame = (current: Publicity[], old: Publicity[]): boolean => {
  if (current.length > old.length || current.length < old.length) {
    return false;
  }
  return (
    current.filter((element, index) => element.id !== old[index].id).length ===
    0
  );
};
watch(props.publicity, (currentValue, oldValue) => {
  if (oldValue.length === 0) return;

  if (!areTheSame(currentValue, oldValue)) {
    isImageLoading.value = true;
    clearTimeout(timeOut);
    currentPublicityIndex.value = 0;
    initPubChange();
  }
});
</script>
<style scoped>
.skeleton {
  cursor: progress;
  background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
    300% 100%;
  animation: skeleton-loading 1.5s linear infinite;
}

@keyframes skeleton-loading {
  to {
    background-position: left;
  }
}
</style>
